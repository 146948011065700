












import { Component, Vue } from 'vue-property-decorator'
import TitleBase from '@/components/atoms/TitleBase.vue'
import TitleTextBase from '@/components/atoms/TitleTextBase.vue'
import TabBase from '@/components/atoms/TabBase.vue'
import StudentTimetablePrivate from '@/components/organisms/StudentTimetablePrivate.vue'
import StudentTimetableGroup from '@/components/organisms/StudentTimetableGroup.vue'

@Component({
  components: {
    TitleBase,
    TitleTextBase,
    TabBase,
    StudentTimetablePrivate,
    StudentTimetableGroup,
  },
})
export default class StudentTimetable extends Vue {
  private breadcrumbs = [
    { text: '設定', href: '/teacher/setting/top' },
    { text: '生徒', href: '/teacher/setting/student' },
    { text: '時間割', active: true },
  ]

  private tabBaseDatas = [
    { id: 1, name: '集団' },
    { id: 2, name: '個別' },
  ]

  private isActiveTab = 1

  private studentDatas = [
    { id: 1, name: 'ビーコン太郎' },
    { id: 2, name: 'ビーコン次郎' },
  ]

  private showAttributeSetting(): void {
    this.$modal.show('modal-attribute-setting')
  }
}
