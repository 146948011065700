
















import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalStudentTimetablePrivate from '@/components/organisms/ModalStudentTimetablePrivate.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    TableBase,
    ModalStudentTimetablePrivate,
  },
})
export default class StudentTimetablePrivate extends Vue {
  private tableBasePerPage: number | null = null

  private tableBaseItems: object[] = [
    {
      subject: '英語',
      week: '月',
      koma: '2コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      subject: '国語',
      week: '火',
      koma: '2コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      subject: '数学',
      week: '水',
      koma: '2コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      subject: '理科',
      week: '木',
      koma: '2コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      subject: '社会',
      week: '金',
      koma: '2コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      subject: '社会',
      week: '金',
      koma: '3コマ目',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetablePrivate, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
  ]

  private tableBaseFields: object[] = [
    { key: 'subject', label: '教科' },
    { key: 'attendance', label: '受講' },
    { key: 'week', label: '曜日' },
    { key: 'koma', label: 'コマ数' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons: string[] = ['action']

  private tableBaseToglles: string[] = ['attendance']

  private showStudentTimetablePrivate(): void {
    this.$modal.show('modal-student-timetable-private')
  }
}
