


































import { Component, Prop, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'
import CheckboxSquare from '@/components/atoms/CheckboxSquare.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
    CheckboxSquare,
  },
})
export default class ModalStudentTimetableGroup extends Vue {
  private gradeOptionDatas: object[] = [
    { text: '中1', value: 0 },
    { text: '中２', value: 1 },
    { text: '中３', value: 2 },
  ]

  private gradeSelectedData = 0

  private subjectOptionDatas: object[] = [
    { text: '国語', value: 'ko' },
    { text: '数学', value: 'su' },
    { text: '英語', value: 'ei' },
    { text: '理科', value: 'ri' },
    { text: '社会', value: 'sh' },
  ]

  private subjectSelectedData = 0

  private classOptionDatas: object[] = [
    { text: '上位', value: 0 },
    { text: '中位', value: 1 },
    { text: '下位', value: 2 },
  ]

  private classSelectedData = 0

  private hideStudentTimetableGroup(): void {
    this.$modal.hide('modal-student-timetable-group')
  }

  @Prop()
  isEdit?: boolean
}
