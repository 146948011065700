


































import { Component, Vue } from 'vue-property-decorator'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import SelectBase from '@/components/atoms/SelectBase.vue'

@Component({
  components: {
    ButtonBase,
    SelectBase,
  },
})
export default class ModalStudentTimetablePrivate extends Vue {
  private subjectOptionDatas: object[] = [
    { text: '国語', value: 'ko' },
    { text: '数学', value: 'su' },
    { text: '英語', value: 'ei' },
    { text: '理科', value: 'ri' },
    { text: '社会', value: 'sh' },
  ]

  private subjectSelectedData = 0

  private komaOptionDatas: object[] = [
    { text: '1コマ目', value: 1 },
    { text: '2コマ目', value: 2 },
    { text: '3コマ目', value: 3 },
    { text: '4コマ目', value: 4 },
    { text: '5コマ目', value: 5 },
    { text: '6コマ目', value: 6 },
    { text: '7コマ目', value: 7 },
    { text: '8コマ目', value: 8 },
  ]

  private komaSelectedData = 0

  private weekOptionDatas: object[] = [
    { text: '月', value: 0 },
    { text: '火', value: 1 },
    { text: '水', value: 2 },
    { text: '木', value: 3 },
    { text: '金', value: 4 },
    { text: '土', value: 5 },
    { text: '日', value: 6 },
  ]

  private weekSelectedData = 0

  private hidewStudentTimetablePrivate(): void {
    this.$modal.hide('modal-student-timetable-private')
  }
}
