
















import { Component, Vue } from 'vue-property-decorator'
import ColoredBox from '@/components/atoms/ColoredBox.vue'
import ButtonBase from '@/components/atoms/ButtonBase.vue'
import TableBase from '@/components/atoms/TableBase.vue'
import ModalStudentTimetableGroup from '@/components/organisms/ModalStudentTimetableGroup.vue'

@Component({
  components: {
    ColoredBox,
    ButtonBase,
    TableBase,
    ModalStudentTimetableGroup,
  },
})
export default class StudentTimetableGroup extends Vue {
  private tableBasePerPage: number | null = null

  private tableBaseItems: object[] = [
    {
      grade: '中1',
      subject: '国語',
      class: '上位',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-1',
      },
      action: [
        { onclick: this.showStudentTimetableGroup, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      grade: '中1',
      subject: '数学',
      class: '下位',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-2',
      },
      action: [
        { onclick: this.showStudentTimetableGroup, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
    {
      grade: '中1',
      subject: '数学',
      class: '中位',
      attendance: {
        checked: true,
        on: '',
        off: '',
        id: 'attendance-3',
      },
      action: [
        { onclick: this.showStudentTimetableGroup, name: '編集' },
        { url: '#', name: '削除' },
      ],
    },
  ]

  private tableBaseFields: object[] = [
    { key: 'subject', label: '教科' },
    { key: 'attendance', label: '受講' },
    { key: 'grade', label: '学年' },
    { key: 'class', label: 'クラス' },
    { key: 'action', label: '' },
  ]

  private tableBaseButtons: string[] = ['action']

  private tableBaseToglles: string[] = ['attendance']

  private isEdit = false

  showStudentTimetableGroup(): void {
    this.$modal.show('modal-student-timetable-group')
  }
}
